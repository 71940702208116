
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.5021 1.40264C10.9577 1.14014 11.4742 1.00195 12 1.00195C12.5258 1.00195 13.0424 1.14015 13.4979 1.40267L13.5 1.40388L20.5 5.40387C20.9556 5.66692 21.334 6.04515 21.5973 6.50062C21.8606 6.95609 21.9995 7.47279 22 7.99888V9.9999C22 10.5522 21.5523 10.9999 21 10.9999C20.4477 10.9999 20 10.5522 20 9.9999V8.5608L17.0595 10.2488C17.0197 10.2756 16.9782 10.2994 16.9354 10.3201L13 12.5792V20.5788L14.5048 19.7211C14.9846 19.4476 15.5953 19.6149 15.8688 20.0947C16.1423 20.5745 15.975 21.1852 15.4952 21.4587L13.5 22.5959L13.4973 22.5975C13.0654 22.8463 12.5786 22.9833 12.0812 22.9968C12.0544 22.9989 12.0273 23 12 23C11.9727 23 11.9456 22.9989 11.9188 22.9968C11.4211 22.9833 10.934 22.8461 10.5019 22.597L10.5 22.5959L3.50386 18.5981L3.5 18.5959C3.04439 18.3329 2.66597 17.9547 2.40269 17.4992C2.13941 17.0437 2.00054 16.527 2 16.0009V7.99888C2.00054 7.47279 2.13941 6.95609 2.40269 6.50062C2.66597 6.04515 3.04439 5.66692 3.5 5.40388L3.50386 5.40165L6.98089 3.41478C6.99551 3.40592 7.01035 3.39744 7.02538 3.38936L10.5021 1.40264ZM11 20.5782V12.5792L4 8.5608V15.9993C4.00025 16.1745 4.04654 16.3466 4.13423 16.4983C4.22169 16.6496 4.34726 16.7753 4.49842 16.863L4.5 16.8639L11 20.5782ZM12.5 3.13594L18.9813 6.8395L16.4968 8.26572L9.51469 4.27041L11.4961 3.13815L11.5 3.13593C11.652 3.04816 11.8245 3.00195 12 3.00195C12.1755 3.00195 12.348 3.04817 12.5 3.13594ZM7.49983 5.42175L14.4865 9.4197L12 10.8471L5.01874 6.8395L7.49983 5.42175ZM22.7071 13.2929C23.0976 13.6834 23.0976 14.3166 22.7071 14.7071L18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L15.2929 16.7071C14.9024 16.3166 14.9024 15.6834 15.2929 15.2929C15.6834 14.9024 16.3166 14.9024 16.7071 15.2929L18 16.5858L21.2929 13.2929C21.6834 12.9024 22.3166 12.9024 22.7071 13.2929Z" fill="black"/>
</svg>

  </template>

  <script>
  export default {
    name: 'PackageArrived',
    inheritAttrs: true,
  }
  </script>
